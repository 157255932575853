import React from "react"
import {
  faCoins,
  faUserShield,
  faTable,
  faRegistered,
  faCity,
  faFileMedical,
  faMoneyCheckAlt,
  faBuilding,
  faChartArea,
  faFileSignature,
  faGavel,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Layout } from "../components"
import { Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import '@fortawesome/fontawesome-svg-core/styles.css';
import { IconProp, config } from "@fortawesome/fontawesome-svg-core"

config.autoAddCss = false;

const specializations = [
  { icon: faUserShield, title: "Prawo cywilne" },
  { icon: faTable, title: "Prawo spółek handlowych" },
  { icon: faRegistered, title: "Postępowania rejestrowe" },
  { icon: faCity, title: "Prawo zamówień publicznych" },
  { icon: faFileMedical, title: "Prawo pracy i ubezpieczeń" },
  { icon: faMoneyCheckAlt, title: "Prawo bankowe" },
  { icon: faBuilding, title: "Prawo budowlane" },
  { icon: faChartArea, title: "Postępowania windykacyjne" },
  { icon: faFileSignature, title: "Prawo kontraktowe" },
  { icon: faGavel, title: "Prawo upadłościowe" },
  { icon: faUserFriends, title: "Prawo rodzinne" },
  { icon: faCoins, title: "Prawo spadkowe" },
]

export default function Offer() {
  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "offer",
        }}
      />
      <Row className="content-container offer-image offer">
        <div className="offer-text">
          <div className="site-header">
            <h2>Zakres usług</h2>
          </div>
          <hr className="line"></hr>
          <div className="offer-introduction">
            <h3>
              Radca Prawny Radosław Rybak zapewnia pozasądową pomoc prawną oraz
              reprezentację przed sądami, organami egzekucyjnymi i organami
              administracji publicznej.
            </h3>
          </div>
        </div>
        <div className="offer-specialization-header">
          <h2>Specjalizacje</h2>
        </div>
        <div className="wrapper">
          {specializations.map((specialization, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6">
              <FontAwesomeIcon icon={specialization.icon as IconProp} size="2x" />
              <h3>{specialization.title}</h3>
            </div>
          ))}
        </div>
      </Row>
    </Layout>
  )
}
